import './App.css';

import { useEffect } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";

import AdminHome from "./components/AdminHome";
import Login from "./components/Auth/Login";

function App() {

  const navigate = useNavigate();

  useEffect(() => {
    const userToken = window.sessionStorage.getItem("USER-AUTH");
    if(userToken == "" || userToken == null) {
      navigate("/login");
    }
  }, []);

  return (
    <Routes>
        <Route path="/*" element={<AdminHome/>} />
        <Route path="/login" element={<Login/>} />
    </Routes>
  );
}

export default App;
