import axios from "axios";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Makes from "./Makes";
import Models from "./Models";
import Years from "./Years";
import Vehicle from "./Vehicle";

function VehiclesHome() {
    return(
        <div className="vehicles-home">
            <h1 className="text-2xl p-3">Seat Cover Solutions - Vehicles Home</h1>
            <Routes>
                <Route path="/" element={<Makes />} />
                <Route path="/:make" element={<Models />} />
                <Route path="/:make/:model" element={<Years />} />
                <Route path="/:make/:model/:year" element={<Vehicle />} />
            </Routes>
        </div>
    );
}

export default VehiclesHome;