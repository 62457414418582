import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function Models() {

    const params = useParams();
    const [models, setModels] = useState({});

    useEffect(() => {
        axios.get(`https://admin.seatcoversolutions.com/api/makes/${params.make}`).then((response) => {
            setModels(response.data.models);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return(
        <div className="models">
            <h3><strong>Select a {params.make} model</strong></h3>
            {Object.keys(models).sort().map((model) => {
                return <><a href={`/vehicles/${params.make}/${model}`}>{model}</a><br/></>
            })}
        </div>
    );

}