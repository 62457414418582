import axios from "axios";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

// Styling imports
import "./Login.css";

function Login() {

    const emailRef = useRef();
    const passwordRef = useRef();
    const errorRef = useRef();
    const navigate = useNavigate();

    function checkLogin() {
        const authPath = "https://admin.seatcoversolutions.com/api/auth";
        axios.post(authPath, {
            email: emailRef.current.value,
            password: passwordRef.current.value
        }).then((res) => {
            console.log(res.data);
            // Successful login
            if(res.data.loginCode === 200) {
                console.log(res.data);
                window.sessionStorage.setItem("USER-AUTH", "Authenticated");
                errorRef.current.innerHTML = "Taking you to the dashboard...";
                errorRef.current.style.color = "lightgreen";
                errorRef.current.style.display = "block";
                navigate("../");
            } 
            // Failed login
            else if(res.data.loginCode === 401) {
                console.log(res.data);
                errorRef.current.innerHTML = "Incorrect email or password";
                errorRef.current.style.color = "red";
                errorRef.current.style.display = "block";
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return(
        <div className="login-page">
            <div className="login-content">
                <div className="login-content-image">
                    <img src="https://cdn.shopify.com/s/files/1/0732/5909/0212/files/AllWhite.png?v=1687886804" alt="" />
                </div>
                <div className="login-content-text">
                    <div className="login-content-row">
                        <img src="https://cdn.shopify.com/s/files/1/0732/5909/0212/files/carIcon.png?v=1687890159" alt="" />
                        <span>Remotely manage your database of vehicles</span>
                    </div>
                    <div className="login-content-row">
                        <img src="https://cdn.shopify.com/s/files/1/0732/5909/0212/files/boxIcon.png?v=1687890159" alt="" />
                        <span>Track orders, view packing lists, and fulfill orders</span>
                    </div>
                    <div className="login-content-row">
                        <img src="https://cdn.shopify.com/s/files/1/0732/5909/0212/files/analyticsIcon.png?v=1687890159" alt="" />
                        <span>View analytics, popular colors, and popular models</span>
                    </div>
                </div>
            </div>
            <div className="login-form">
                <div className="login-form-wrapper">
                    <h1>Log In</h1>
                    <div className="login-form-input">
                        <label htmlFor="email">Email Address</label>
                        <input type="text" name="email" id="email" ref={emailRef} />
                    </div>
                    <div className="login-form-input">
                        <label htmlFor="password">Password</label>
                        <input type="password" name="password" id="password" ref={passwordRef} />
                    </div>
                    <p ref={errorRef} style={{ color: "red", display: "none" }}>Incorrect email or password. Please try again.</p>
                    <div className="login-form-submit">
                        <button onClick={() => checkLogin()}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;