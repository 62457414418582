function OrderErrorCard(props) {

    const shopOrder = props.shopOrder;
    const shipbobOrder = props.shipbobOrder;
    console.log(shipbobOrder);

    return(
        <div className="error-card">

            {/* The order was unable to be sent to ShipBob */}
            {shopOrder.shipbob_status === 'error' || !("shipbob_order_id" in shopOrder) ?
                <>
                <span>There was an error sending the order to ShipBob.</span>
                </>
            : null}

            {"shipbob_order_id" in shopOrder && (shipbobOrder.status === "Exception" || shipbobOrder.status === "ImportReview") ? 
                <>
                <span>An <strong>{shopOrder.shipbob_status}</strong> occured in the ShipBob dashboard for this order ({shopOrder.shipbob_order_id}):</span>
                <ol>
                {shipbobOrder.shipments.map((shipment, index) => {
                    return <li>
                        <a className="shipment-link" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            Shipment {shipment.id}: {shipment.status}
                        </a>
                        <div className="collapse" id="collapseExample">
                            <ul>
                            {shipment.status_details.map((detail, index) => {
                                return <li>{detail.name} ({detail.inventory_id})</li>
                            })}
                            </ul>
                        </div>
                    </li>
                })}
                </ol>
                </>
            : null}

        </div>
    );
}

export default OrderErrorCard;