import { useState, useEffect } from "react";
import axios from "axios";

export default function Makes() {

    const [makes, setMakes] = useState([]);

    useEffect(() => {
        axios.get('https://admin.seatcoversolutions.com/api/makes/all').then((response) => {
            setMakes(response.data);
        }).catch((error) => {
            console.log(error);
        }); 
    }, []);

    return(
        <div className="makes">
            <h3>Select a vehicle manufacturer</h3>
            {makes.map((make) => {
                return <><a href={`/vehicles/${make}`}>{make}</a><br/></>
            })}
        </div>
    );
}