import React, { useState } from "react";
import { data as defaultData } from "./Table/data";

import "./Inventory.css";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { columns } from "./Table/columns";

const InventoryHome = () => {
  const [data, setData] = useState(() => [...defaultData]);
  const [originalData, setOriginalData] = useState(() => [...defaultData]);
  const [selectedRow, setSelectedRow] = useState({});

  const table = useReactTable({
	data,
	columns,
	getCoreRowModel: getCoreRowModel(),
	meta: {
	  selectedRow,
	  setSelectedRow,
	  revertData: (rowIndex, revert) => {
		if (revert) {
		  setData((old) =>
			old.map((row, index) =>
			  index === rowIndex ? originalData[rowIndex] : row
			)
		  );
		} else {
		  setOriginalData((old) =>
			old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
		  );
		}
	  },
	  updateData: (rowIndex, columnId, value) => {
		setData((old) =>
		  old.map((row, index) => {
			if (index === rowIndex) {
			  return {
				...old[rowIndex],
				[columnId]: value,
			  };
			}
			return row;
		  })
		);
	  },
	},
  });

  return (
	<>
	  <div className="inventory-home">
		<h1 className="text-3xl font-bold underline">
		  Seat Cover Solutions - Inventory Management
		</h1>
	  </div>
	  <div className="overflow-x-auto">
		<table className="table table-zebra">
		  <thead>
			{table.getHeaderGroups().map((headerGroup) => (
			  <tr key={headerGroup.id}>
				{headerGroup.headers.map((header) => (
				  <th key={header.id}>
					{header.isPlaceholder
					  ? null
					  : flexRender(
						  header.column.columnDef.header,
						  header.getContext()
						)}
				  </th>
				))}
			  </tr>
			))}
		  </thead>
		  <tbody>
			{table.getRowModel().rows.map((row) => (
			  <tr key={row.id}>
				{row.getVisibleCells().map((cell) => (
				  <td key={cell.id}>
					{flexRender(cell.column.columnDef.cell, cell.getContext())}
				  </td>
				))}
			  </tr>
			))}
		  </tbody>
		</table>
	  </div>
	</>
  );
};

export default InventoryHome;
