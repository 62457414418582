import "./Settings.css";

import { Routes, Route } from "react-router-dom";
import { Navbar, Nav } from "rsuite";

// Page imports
import OrderSettings from "./OrderSettings";
import FulfillmentSettings from "./FulfillmentSettings";
import VehiclesSettings from "./VehiclesSettings";
import AnalyticsSettings from "./AnalyticsSettings";
import InventorySettings from "./InventorySettings";
import SupplyChainSettings from "./SupplyChainSettings";

export default function Settings() {

    function handleSelect(eventKey) {
        console.log(eventKey);
    }

    return(
        <div className="settings">
            <Navbar>
                <Nav onSelect={handleSelect} appearance="subtle">
                    <Nav.Item href={'/settings/orders'}>Orders</Nav.Item>
                    <Nav.Item href={'/settings/fulfillment'}>Fulfillment</Nav.Item>
                    <Nav.Item href={'/settings/vehicles'}>Vehicles</Nav.Item>
                    <Nav.Item href={'/settings/analytics'}>Analytics</Nav.Item>
                    <Nav.Item href={'/settings/inventory'}>Inventory</Nav.Item>
                    <Nav.Item href={'/settings/supply-chain'}>Supply Chain</Nav.Item>
                </Nav>
            </Navbar>

            <Routes>
                <Route path="/orders" element={<OrderSettings />} />
                <Route path="/fulfillment" element={<FulfillmentSettings />} />
                <Route path="/vehicles" element={<VehiclesSettings />} />
                <Route path="/analytics" element={<AnalyticsSettings />} />
                <Route path="/inventory" element={<InventorySettings />} />
                <Route path="/supply-chain" element={<SupplyChainSettings />} />
            </Routes>
        </div>
    );
}