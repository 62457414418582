import { useState, useEffect } from "react";
import axios from "axios";

function Orders() {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get("https://admin.seatcoversolutions.com/api/order/all").then((response) => {
            setOrders(response.data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    function orderGapArray(index) {
        const array = [];
        for(let id = parseInt(orders[index - 1].name) + 1; id < parseInt(orders[index].name); id++) 
            array.push(id);
        return array;
    }

    return(
        <div className="orders">
            <h1>Orders</h1>
            {loading ? <><div class="spinner-border text-danger" role="status"></div><span> Loading orders...</span></> :
                <>
                {orders.map((order, index) => {
                    return <>
                        {index > 0 ? <>
                            {orderGapArray(index).map((orderId, index) => {
                                return <><a href="/" style={{ color: "orange" }}>Order #{orderId} - Not Found</a><br/></>
                            })}
                        </> : null}
                        <a href={`/orders/${order.order_number}`} style={ order.shopify_status === "cancelled" ? { textDecoration: "line-through", color: "red" } : {}}>Order #{order.order_number} - {order.shipbob_status}</a><br/>
                    </>
                })}
                </>
            }
        </div>
    );
}

export default Orders;