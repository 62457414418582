import { useEffect, useState } from "react";
import { DateRangePicker } from 'rsuite';

function SupplyChain() {

    const [selectedDateRange, setSelectedDateRange] = useState([new Date(), new Date()]);
    
    function handleDateChange(value) {
        setSelectedDateRange(value);
    }

    return(
        <div className="supply-chain">
            <div className="header pb-3">
                <p className="text-2xl">Supply Chain</p>
            </div>
            <DateRangePicker 
                character={' - '}
                format={"MMM dd, yyyy"}
                defaultValue={[new Date(), new Date()]}
                onChange={handleDateChange}
            />
        </div>
    );
}

export default SupplyChain;

// things to build: 
//   * reorder points for colors
//   * automatically determine how long it takes for a certain color
//   * view shipments, statuses, and when they will arrive
//   * give a demand rating to each product
//   * view occuring trends in demand
//   * determine sales forecasting (good algorithm)
//   * project when colors will run out
//   * project when skus will run out
//   * filter ordered counts for certain skus, colors, etc
//   * sort by fulfilled, unfulfilled, etc.
//   * predict various points in supply chain process