export type Inventory = {
    sku: string;
    inStock: number;
    name: string;
    location: string;
    inRoute: number;
  };
  
  export const data: Inventory[] = [
    {
      sku: "blk-something",
      name: "black seat thing",
      inStock: 12,
      location: "minnesota",
      inRoute: 1,
    },
    {
      sku: "wht-12",
      name: "white seat thing",
      inStock: 19,
      location: "Texas",
      inRoute: 34,
    },
    {
      sku: "gry-19",
      name: "gray seat thing",
      inStock: 33333,
      location: "Iowa",
      inRoute: 1234234,
    },
  ];