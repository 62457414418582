import { createColumnHelper } from '@tanstack/react-table'
import { EditableCell } from './EditableCell'
import { Inventory } from './data'
import { EditAction } from './EditAction'

const columnHelper = createColumnHelper<Inventory>()

export const columns = [
    columnHelper.accessor("sku", {
      header: "SKU #",
      cell: EditableCell,
      meta: {
        type: "text",
      },
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: EditableCell,
      meta: {
        type: "text",
      },
    }),
    columnHelper.accessor("inStock", {
      header: "In Stock",
      cell: EditableCell,
      meta: {
        type: "number",
      },
    }),
    columnHelper.accessor("location", {
      header: "location",
      cell: EditableCell,
      meta: {
        type: "text",
      },
    }),
    columnHelper.accessor("inRoute", {
      header: "In Route",
      cell: EditableCell,
      meta: {
        type: "number",
      },
    }),
    //   if we wanted to add a "select", we could add the options here
    columnHelper.display({
      id: "edit",
      cell: EditAction,
    }),
  ];