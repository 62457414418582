import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function Vehicle() {

    const params = useParams();
    const [vehicleData, setVehicleData] = useState({ hr_data: { front: {}, back: {}, both: {} }, mid_data: {} });

    useEffect(() => {
        axios.get(`https://admin.seatcoversolutions.com/api/vehicle?make=${params.make}&model=${params.model}&year=${params.year}`).then((response) => {
            setVehicleData(response.data);
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return(
        <div className="vehicle">
            <h2 className="text-xl">Vehicle: {params.year} {params.make} {params.model}</h2>
            <h2>Compat: {vehicleData.compat}</h2>
            <strong>Front Data</strong><br/>
            {Object.keys(vehicleData.hr_data.front).map((sku) => {
                return <><span className="pl-3">- {sku}: {vehicleData.hr_data.front[sku]}</span><br/></>
            })}
            <strong>Back Data</strong><br/>
            {Object.keys(vehicleData.hr_data.back).map((sku) => {
                return <><span className="pl-3">- {sku}: {vehicleData.hr_data.back[sku]}</span><br/></>
            })}
            <strong>Both Data</strong><br/>
            {Object.keys(vehicleData.hr_data.both).map((sku) => {
                return <><span className="pl-3">- {sku}: {vehicleData.hr_data.both[sku]}</span><br/></>
            })}
            <strong>Mid Data</strong><br/>
            {Object.keys(vehicleData.mid_data).map((sku) => {
                return <><span className="pl-3">- {sku}: {vehicleData.mid_data[sku]}</span><br/></>
            })}
        </div>
    );
}