import { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./AnalyticsHome.css";

function AnalyticsHome() {

    // Get all order data
    const [orderData, setOrderData] = useState({});
    const [orderShipmentData, setOrderShipmentData] = useState({});

    // Color count table
    const [colorCount, setColorCount] = useState(["Loading..."]);
    const [colorCountSort, setColorCountSort] = useState(1);

    // Model count table
    const [modelCount, setModelCount] = useState(["Loading..."]);
    const [modelCountSort, setModelCountSort] = useState(1);

    // Sku data and calculator container
    const [skuCount, setSkuCount] = useState({});
    const [calcSkuCount, setCalcSkuCount] = useState({});
    const skuCalcRef = useRef();
    const orderCountRef = useRef();

    function toggleColorCountSort() {
        if(colorCountSort) { 
            const sorted = Object.fromEntries(Object.entries(colorCount).sort(([,a],[,b]) => a - b));
            console.log(sorted);
            setColorCount(sorted);
            setColorCountSort(0);
        }
        else {
            const sorted = Object.fromEntries(Object.entries(colorCount).sort(([,a],[,b]) => b - a));
            console.log(sorted);
            setColorCount(sorted);
            setColorCountSort(1);
        }
    }

    function toggleModelCountSort() {
        if(modelCountSort) {
            const sorted = Object.fromEntries(Object.entries(modelCount).sort(([,a],[,b]) => a - b));
            setModelCount(sorted);
            setModelCountSort(0);
        }
        else {
            const sorted = Object.fromEntries(Object.entries(modelCount).sort(([,a],[,b]) => b - a));
            setModelCount(sorted);
            setModelCountSort(1);
        }
    }

    function getColorCount(orderInData) {
        const data = {};
        Object.keys(orderInData).forEach((orderId) => {
            const color = orderInData[orderId].color;
            if(color in data)
                data[color] = data[color] + 1;
            else 
                data[color] = 1;
        });
        const sorted = Object.fromEntries(Object.entries(data).sort(([,a],[,b]) => b - a) );
        return sorted;
    }

    function getModelCount(orderInData) {
        const data = {};
        Object.keys(orderInData).forEach((orderId) => {
            const make = orderInData[orderId].make;
            const model = orderInData[orderId].model;
            if(`${make} ${model}` in data)
                data[`${make} ${model}`] = data[`${make} ${model}`] + 1;
            else 
                data[`${make} ${model}`] = 1;
        });
        const sorted = Object.fromEntries(Object.entries(data).sort(([,a],[,b]) => b - a) );
        return sorted;
    }

    function getSkuCount(orderInData) {
        const data = {};
        Object.keys(orderInData).forEach((orderId) => {
            if(parseInt(orderId) > 21329) {
                const skuData = orderInData[orderId].skuData;
                Object.keys(skuData).forEach((sku) => {
                    if(!sku.startsWith("R")) {
                        if(sku in data)
                            data[sku] = data[sku] + parseInt(orderInData[orderId].skuData[sku]);
                        else 
                            data[sku] = 1;
                    }
                    else {
                        // If the sku is a rear set SKU, check if there is an extra front we can use to make a full set, otherwise just make a new full set
                        const baseSku = sku.replace("R", "");
                        const frontSku = `${baseSku}F`;
                        if(data[frontSku] > 0) 
                            data[frontSku] = data[frontSku] - 1;
                        data[baseSku] = data[baseSku] + 1;
                    }
                });
            }
        });
        const sorted = Object.fromEntries(Object.entries(data).sort(([,a],[,b]) => b - a));
        return sorted;
    }
    function skuFromOrderCount() {
        const orderCount = orderCountRef.current.value;
        const data = {};
        Object.keys(skuCount).forEach((sku) => {
            data[sku] = Math.round((parseInt(orderCount) * parseInt(skuCount[sku])) / (Object.keys(orderData).length - 389));
        });
        console.log(data);
        setCalcSkuCount(data);
        alert("Data calculated successfully.");
    }

    function copySkuDataToClipboard() {
        let clipboardString = "";
        Object.keys(calcSkuCount).forEach((sku) => {
            clipboardString += `${sku}\n`;
        });
        Object.keys(calcSkuCount).forEach((sku) => {
            clipboardString += `${calcSkuCount[sku]}\n`;
        });
        navigator.clipboard.writeText(clipboardString).then(() => {
            // Alert the user that the action took place.
            // Nobody likes hidden stuff being done under the hood!
            alert("Copied to clipboard");
        });
    }

    function toggleSKUCalculator() {
        const container = skuCalcRef.current;
        if(container.classList.contains("open"))
            skuCalcRef.current.classList.remove("open");
        else 
            skuCalcRef.current.classList.add("open");
    }

    useEffect(() => {
        // Make request to obtain all order data
        axios.get("https://scs-administration-773ba.web.app/api/all-orders")
            .then(orderResponse => {
                setOrderData(orderResponse.data);
                const colorCountData = getColorCount(orderResponse.data);
                setColorCount(colorCountData);
                const modelCountData = getModelCount(orderResponse.data);
                setModelCount(modelCountData);
                const skuCount = getSkuCount(orderResponse.data);
                console.log(skuCount);
                setSkuCount(skuCount);
            });
        // Make request to obtain all shipment data for every order
        axios.get("https://scs-administration-773ba.web.app/api/shipments-for-orders")
            .then(orderShipmentData => {
                setOrderShipmentData(orderShipmentData.data);
            });        
    }, []);

    return(
        <div className="analytics-home">
            <h1>Seat Cover Solutions - Analytics</h1>

            {/* Sku calculator container */}
            <button onClick={() => toggleSKUCalculator()}>SKU Calculator</button>
            <div className="sku-calc-container" ref={skuCalcRef}>
                <h2>SKU Order Calculator</h2>
                <label htmlFor="order-count" ref={orderCountRef}>Enter order count:</label>
                <input type="number" name="order-count" id="order-count" ref={orderCountRef}/>
                <button onClick={() => skuFromOrderCount()}>Calculate</button>
                {/* <button onClick={() => copySkuDataToClipboard()}>Copy Data</button> */}
                <div className="sku-data-container">
                    {Object.keys(calcSkuCount).map((sku, i) => {
                        return <p class="calculated-sku">{sku},{calcSkuCount[sku]}</p>
                    })}
                </div>
            </div>

            <div className="count-analytics">
                {/* Popular colors */}
                <div className="color-count">
                    <h3>Color Count</h3>
                    <table>
                        <tr>
                            <th>Color Name</th>
                            <th className="table-count" onClick={() => toggleColorCountSort()}>Count</th>
                        </tr>
                        {Object.keys(colorCount).map((color, i) => {
                            return <tr>
                                <td>{color}</td>
                                <td>{colorCount[color]}</td>
                            </tr>
                        })}
                    </table>
                </div>

                {/* Popular models */}
                <div className="model-count">
                    <h3>Model Count</h3>
                    <table>
                        <tr>
                            <th>Model Name</th>
                            <th className="table-count" onClick={() => toggleModelCountSort()}>Count</th>
                        </tr>
                        {Object.keys(modelCount).map((model, i) => {
                            if(modelCount[model] > 25)
                                return <tr> <td>{model}</td> <td>{modelCount[model]}</td> </tr>
                        })}
                    </table>
                </div>
            </div>
        </div>
    );   
}

export default AnalyticsHome;