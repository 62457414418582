function Dashboard() {

    function logout() {
        window.sessionStorage.removeItem("USER-AUTH");
    }

    return(
        <div className="content">
            <h1>Seat Cover Solutions Admin</h1>
            <a href="/login">Login</a><br />
            <a href="/inventory">Inventory</a><br />
            <a href="/vehicles">Vehicles</a><br />
            <a href="/fulfillment">Fulfillment</a><br />
            <a href="/analytics">Analytics</a><br />
            <a href="/login" onClick={() => logout()}>Logout</a>
        </div>
    );
}

export default Dashboard;