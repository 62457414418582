import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./FulfillmentHome.css";

function FulfillmentHome() {

    // Store and keep track of order data
    const [originalData, setOriginalData] = useState({});
    const [orderData, setOrderData] = useState({});
    const [shownCount, setShownCount] = useState(Object.keys(orderData).length);

    // Store and keep track of inventory data
    const [shipmentData, setShipmentData] = useState({});
    const [orderShipmentData, setOrderShipmentData] = useState({});

    // keep track of the selected order
    const [selectedOrder, setSelectedOrder] = useState("10000");
    
    // Create DOM references
    const fulfillMenuRef = useRef();

    function searchForVehicle(e) {
        const targetCondition = e.target.value;
        if(targetCondition == "") {
            setOrderData(originalData);
            setShownCount(Object.keys(originalData).length);
            return;
        }
        const filteredOrderNums = Object.keys(originalData).filter((id) => {
            let vehicle = `${originalData[id].year} ${originalData[id].make} ${originalData[id].model}`.toLowerCase();
            return vehicle.includes(targetCondition.toLowerCase());
        });
        const filteredOrderData = {};
        filteredOrderNums.forEach((number) => { filteredOrderData[number] = originalData[number] });
        setOrderData(filteredOrderData);
        setShownCount(Object.keys(filteredOrderData).length);
    }

    function searchForOrder(e) {
        const targetCondition = e.target.value;
        if(targetCondition == "") {
            setOrderData(originalData);
            setShownCount(Object.keys(originalData).length);
            return;
        }
        const filteredOrderNums = Object.keys(orderShipmentData).filter((id) => {
            return id.startsWith(targetCondition)
        });
        const filteredOrderData = {};
        filteredOrderNums.forEach((number) => { filteredOrderData[number] = originalData[number] });
        setOrderData(filteredOrderData);
        setShownCount(Object.keys(filteredOrderData).length);
    }

    function searchByDSO(e) {
        const targetCondition = e.target.value;
        if(targetCondition == "") {
            setOrderData(originalData);
            setShownCount(Object.keys(originalData).length);
            return;
        }
        const filteredOrderNums = Object.keys(orderShipmentData).filter((id) => {
            return orderShipmentData[id].dso == targetCondition
        });
        const filteredOrderData = {};
        filteredOrderNums.forEach((number) => { filteredOrderData[number] = originalData[number] });
        setOrderData(filteredOrderData);
        setShownCount(Object.keys(filteredOrderData).length);
    }

    function setSelectedOrderShippingLabelPrinted() {
        console.log(`SETTING ORDER: ${selectedOrder} PRINTED`);
        axios.post("https://scs-administration-773ba.web.app/api/order-printed", {
            orderId: selectedOrder,
            labelPrinted: true
        }).then((res) => {
            if(res.data == "SUCCESS") {
                orderData[selectedOrder].labelPrinted = true;
                setOrderData(orderData);
                document.querySelector(`#order-entry-${selectedOrder}`).style.background = "#ffff00";
                // console.log(`.order-entry-${selectedOrder}`);
                closeFulfillOrderMenu();
            }
        });
    }

    function setSelectedOrderFulfilled(state) {
        console.log(`SETTING ORDER: ${selectedOrder} FULFILLED`);
        axios.post("https://scs-administration-773ba.web.app/api/order-fulfill", {
            orderId: selectedOrder,
            state: true
        }).then((res) => {
            if(res.data == "SUCCESS") {
                orderData[selectedOrder].fulfilled = true;
                setOrderData(orderData);
                document.querySelector(`#order-entry-${selectedOrder}`).style.background = "#00ff00";
                closeFulfillOrderMenu();
            }
        });
    }

    /**
     * Filter by a given condition when the filter dropdown is changed
     * 
     * @param {Event} e - the event object for filter change
     * @param {String} byCondition - the condition on which to filter on
     */
    function filterOrderDataBy(e, byCondition) {
        const targetCondition = e.target.value;
        if(targetCondition == "-") {
            setOrderData(originalData);
            setShownCount(Object.keys(originalData).length);
            return;
        }
        const filteredOrderNums = Object.keys(originalData).filter((id) => {
            if(byCondition == "seats")
                return originalData[id].seats == targetCondition
            else if(byCondition == "color")
                return originalData[id].color == targetCondition
        });
        const filteredOrderData = {};
        filteredOrderNums.forEach((number) => { filteredOrderData[number] = originalData[number]; })
        setOrderData(filteredOrderData);
        setShownCount(Object.keys(filteredOrderData).length);
    }   

    function filterShipmentDataBy(e, byCondition) {
        const targetCondition = e.target.value;
        if(targetCondition == "-") {
            setOrderData(originalData);
            setShownCount(Object.keys(originalData).length);
            return;
        }
        const filteredOrderNums = Object.keys(orderShipmentData).filter((id) => {
            if(byCondition == "shipCode")
                return orderShipmentData[id].shipCode == targetCondition
            else if(byCondition == "arrival")
                return orderShipmentData[id].arrival == targetCondition
        });
        const filteredOrderData = {};
        filteredOrderNums.forEach((number) => { filteredOrderData[number] = originalData[number] });
        setOrderData(filteredOrderData);
        setShownCount(Object.keys(filteredOrderData).length);
    }

    function openFulfillOrderMenu(id) {
        const fulfillMenu = fulfillMenuRef.current;
        removeSkusFromFulfillMenu(fulfillMenu);
        setSelectedOrder(id);
        const skuData = originalData[id].skuData;
        Object.keys(skuData).forEach((sku) => {
            const skuCount = skuData[sku];
            if(skuCount > 0) {
                const newSkuElem = document.createElement("p");
                newSkuElem.classList.add("sku-item");
                newSkuElem.style.margin = "0";
                newSkuElem.innerHTML = `${sku}: ${skuCount}`;
                fulfillMenu.insertBefore(newSkuElem, fulfillMenu.lastChild);
            }
        });
        fulfillMenu.classList.add("open");
    }
    function closeFulfillOrderMenu() {
        const fulfillMenu = fulfillMenuRef.current;
        fulfillMenu.classList.remove("open");
        removeSkusFromFulfillMenu(fulfillMenu);
    }
    function removeSkusFromFulfillMenu(fulfillMenu) {
        const skuElems = fulfillMenu.childNodes;
        const elemsToRemove = [];
        skuElems.forEach((elem) => {
            if(elem.classList.contains("sku-item")) {
                elemsToRemove.push(elem);
            }
        });
        elemsToRemove.forEach((elem) => {
            fulfillMenu.removeChild(elem);
        })
    }

    /**
     * Get styling for a table cell based on shipment code and order id
     * 
     * @param {String} id - order id
     * @returns - styling based on what shipment the order is in
     */
    function getShipCodeStyling(id) {
        if(id in orderShipmentData) {
            const shipCode = orderShipmentData[id].shipCode;
            switch(shipCode) {
                case "S001":
                    return { background: "lightgreen" }
                case "S002":
                    return { background: "#11fc05" }
                case "S003":
                    return { background: "darkgreen", color: "white" }
                case "S004":
                    return { background: "#ff7d7d" }
                case "S005":
                    return { background: "red", color: "white" }
                case "S006":
                    return { background: "darkred", color: "white" }
                case "S007":
                    return { background: "#3b0101", color: "white" }
            }
        }
        else
            return {};
    }

    function getDSOStyling(id) {
        if(id in orderShipmentData) {
            const dso = orderShipmentData[id].dso;
            if(dso <= 10) 
                return { background: "lightgreen" }
            else if(dso <= 30)
                return { background: "#11fc05" }
            else if(dso <= 50)
                return { background: "darkgreen", color: "white" }
            else if(dso <= 60)
                return { background: "#ff7d7d" }
            else if(dso > 60)
                return { background: "darkred", color: "white" }
        }
        else
            return {};
    }

    function getRowStyling(order) {
        if(order.fulfilled) 
            return { background: "#00ff00" };
        else if("labelPrinted" in order) {
            if(order.labelPrinted)
                return { background: "#ffff00" };
            else
                return {};
        }
        else
            return {};
    }

    async function animatedScrollToTop() {
        let scrollPos = window.scrollY;
        while(scrollPos > 0) {
            if(scrollPos < 50)
                scrollPos = scrollPos - 1;
            else if(scrollPos < 100)
                scrollPos = scrollPos - 5;
            else if(scrollPos < 300)
                scrollPos = scrollPos - 10;
            else if(scrollPos < 700) 
                scrollPos = scrollPos - 25;
            else
                scrollPos = scrollPos - 150;
            window.scrollTo(0, scrollPos);
            await new Promise(r => setTimeout(r, 1));
        }
    }

    useEffect(() => {
        // Make request to obtain all order data
        axios.get("https://scs-administration-773ba.web.app/api/all-orders")
            .then(orderResponse => {
                setOrderData(orderResponse.data)
                setOriginalData(orderResponse.data);
                setShownCount(Object.keys(orderResponse.data).length);
            });
        // Make request to obtain all inventory data
        axios.get("https://scs-administration-773ba.web.app/api/all-shipments")
            .then(inventoryResponse => {
                setShipmentData(inventoryResponse.data);
            });
        // Make request to obtain all shipment data for every order
        axios.get("https://scs-administration-773ba.web.app/api/shipments-for-orders")
            .then(orderShipmentData => {
                setOrderShipmentData(orderShipmentData.data);
            });
    }, []);

    return(
        <div className="fulfillment-home">

            <h1>Fulfillment Home</h1>
            <button className="back-to-top-button" onClick={() => animatedScrollToTop()}>Scroll To Top</button>

            {/* Filter by seats */}
            <label htmlFor="seats">Filter by seats: </label>
            <select name="seats" id="seats" onChange={(e) => filterOrderDataBy(e, "seats")}>
                <option value="-">-</option>
                <option value="back">back</option>
                <option value="front">front</option>
                <option value="both">both</option>
            </select>

            {/* Filter by color */}
            <label htmlFor="color">Filter by color: </label>
            <select name="color" id="color" onChange={(e) => filterOrderDataBy(e, "color")}>
                <option value="-">-</option>
                <option value="Black">Black</option>
                <option value="Beige">Beige</option>
                <option value="Wine Red">Wine Red</option>
                <option value="Caramel">Caramel</option>
                <option value="Coffee">Coffee</option>
                <option value="White">White</option>
                <option value="Black/Red">Black/Red</option>
                <option value="Black/Grey">Black/Grey</option>
                <option value="Black/Pink">Black/Pink</option>
                <option value="Black/White">Black/White</option>
                <option value="Black/Blue">Black/Blue</option>
                <option value="Dreamsicle">Dreamsicle</option>
            </select>

            {/* Filter by shipment code */}
            <label htmlFor="shipCode">Filter by shipment code: </label>
            <select name="shipCode" id="shipCode" onChange={(e) => filterShipmentDataBy(e, "shipCode")}>
                <option value="-">-</option>
                <option value="S001">S001</option>
                <option value="S002">S002</option>
                <option value="S003">S003</option>
                <option value="S004">S004</option>
                <option value="S005">S005</option>
                <option value="S006">S006</option>
                <option value="S007">S007</option>
            </select>

            {/* Filter by shipment arrival date */}
            <label htmlFor="arrival">Filter by shipment arrival: </label>
            <select name="arrival" id="arrival" onChange={(e) => filterShipmentDataBy(e, "arrival")}>
                <option value="-">-</option>
                <option value="22-May-2023">May 22</option>
                <option value="29-May-2023">May 29</option>
                <option value="05-Jun-2023">Jun 5</option>
                <option value="12-Jun-2023">Jun 12</option>
                <option value="19-Jun-2023">Jun 19</option>
                <option value="26-Jun-2023">Jun 26</option>
                <option value="03-Jul-2023">Jul 3</option>
            </select>

            {/* Search for an order */}
            <label htmlFor="search-order">Search for order: </label>
            <input type="text" name="search-order" id="search-order" onChange={(e) => searchForOrder(e)} />

            {/* Search for a vehicle */}
            <label htmlFor="search-vehicle">Search for vehicle:</label>
            <input type="text" name="search-vehicle" id="search-vehicle" onChange={(e) => searchForVehicle(e)} />

            {/* Search for a vehicle */}
            <label htmlFor="search-dso">Search for DSO:</label>
            <input type="text" name="search-dso" id="search-dso" onChange={(e) => searchByDSO(e)} />

            {/* Text to display shown order count */}
            <p className="order-count-text">Showing {shownCount} orders</p>

            <div className="order-fulfill-menu" ref={fulfillMenuRef}>
                <span className="close-fulfill-menu" onClick={() => closeFulfillOrderMenu()}>x</span>
                <h2>Fulfill Order {selectedOrder}</h2>
                <button onClick={() => setSelectedOrderShippingLabelPrinted()}>Printed Label</button>
                <button onClick={() => setSelectedOrderFulfilled(true)}>Fulfill Order</button>
            </div>

            <table>
                {/* Table header row */}
                <tr>
                    <th>Order Number</th>
                    <th>Cust. Name</th>
                    <th>Seats</th>
                    <th>Color</th>
                    <th>Vehicle</th>
                    <th>Ship No.</th>
                    <th>Arrival Date</th>
                    <th>DSO</th>
                    <th>PTime</th>
                </tr>
                {/* Create a new table entry row for each order */}
                {
                    Object.keys(orderData).map((id, i) => {
                        return <tr className="order-entry" id={`order-entry-${id}`} onClick={() => openFulfillOrderMenu(id)} style={getRowStyling(orderData[id])}>
                            {console.log(getRowStyling(orderData[id]))}
                            <td>{id}</td>
                            <td>{orderData[id].name}</td>
                            <td>{orderData[id].seats}</td>
                            <td>{orderData[id].color}</td>
                            <td>{orderData[id].year} {orderData[id].make} {orderData[id].model}</td>
                            {/* First line is the function call to save time and appropriately
                                calculate inventory counts. This function call saves all the data in
                                the 'individShipmentData' object to be referenced for the next 2lines. */}
                            {/* {console.log(orderShipmentData[id])}  */}
                            <td style={getShipCodeStyling(id)}>{id in orderShipmentData ? orderShipmentData[id].shipCode : ""}</td>
                            <td>{id in orderShipmentData ? orderShipmentData[id].arrival : ""}</td>
                            <td style={getDSOStyling(id)}>{id in orderShipmentData ? orderShipmentData[id].dso : ""}</td>
                            <td>{orderData[id].promiseTime}</td>
                        </tr>
                    })
                }
            </table>
        </div>
    );
}

export default FulfillmentHome;