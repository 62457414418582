// Node package imports
import { Routes, Route } from "react-router-dom";

// Relative component imports
import Dashboard from "./Dashboard/Dashboard";
import InventoryHome from "./Inventory/InventoryHome.tsx";
import FulfillmentHome from "./Fulfillment/FulfillmentHome";
import VehiclesHome from "./Vehicles/VehiclesHome";
import AnalyticsHome from "./Analytics/AnalyticsHome";
import Orders from "./Orders/Orders";
import Order from "./Orders/Order";
import Menu from "./Menu";
import SupplyChain from "./SupplyChain/SupplyChain";
import Settings from "./Settings/Settings";

// Styling imports
import "./AdminHome.css";
import React from "react";

function AdminHome() {
  return (
    <div className="admin-home">
      <Menu />
      <div className="admin-content">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/fulfillment" element={<FulfillmentHome />} />
          <Route path="/vehicles/*" element={<VehiclesHome />} /> 
          <Route path="/analytics" element={<AnalyticsHome />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:number" element={<Order />} />
          <Route path="/inventory" element={<InventoryHome />} />
          <Route path="/supply-chain" element={<SupplyChain />} />
          <Route path="/settings/*" element={<Settings />} />
        </Routes>
      </div>
    </div>
  );
}

export default AdminHome;
