import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function Years() {

    const params = useParams();
    const [years, setYears] = useState({});

    useEffect(() => {
        axios.get(`https://admin.seatcoversolutions.com/api/models/${params.model}?make=${params.make}`).then((response) => {
            setYears(response.data.years);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return(
        <div className="years">
            {Object.keys(years).sort().reverse().map((year) => {
                return <><a href={`/vehicles/${params.make}/${params.model}/${year}`}>{year}</a><br/></>
            })}
        </div>
    );
}